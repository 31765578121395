export default [
    {
        path: '/apps/calendar',
        name: 'apps-calendar',
        // component: () => import('@/views/pages/calendar/Calendar.vue'),
        component: () => import('@/views/pages/calendar/Index.vue'),
        meta: {
            pageTitle: 'Vencimiento de Documentos (Compras)',
            breadcrumb: [
                {
                    text: 'Dashboard',
                },
                {
                    text: 'Vencimiento de Documentos',
                    active: true,
                },
            ],
        },        
    },
    {
        path: '/apps/margin-documents',
        name: 'apps-margin-documents',
        component: () => import('@/views/pages/MarginDocuments.vue'),
        meta: {
            pageTitle: 'Margen de Documentos',
            breadcrumb: [
                {
                    text: 'Dashboard',
                },
                {
                    text: 'Margen de Documentos',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/apps/maintenances',
        name: 'apps-maintenances',
        component: () => import('@/views/pages/maintenances/Index.vue'),
        meta: {
            pageTitle: 'Mantenimiento de Unidades',
            breadcrumb: [
                {
                    text: 'Dashboard',
                },
                {
                    text: 'Mantemimientos',
                    active: true,
                },
            ],
        },
    },

]