import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'

export default {
    namespaced: true,
    state: {
        isRealodAbierta: false,
        isRealodEnRuta: false,
        isRealoadCerrada: false,
        isRealodClientePasa: false,
    },
    getters: {
        getIsRealodAbierta: state => state.isRealodAbierta,
        getIsRealodEnRuta: state => state.isRealodEnRuta,
        getIsRealoadCerrada: state => state.isRealoadCerrada,
        getIsRealodClientePasa: state => state.isRealodClientePasa,

    },
    mutations: {
        SET_IS_RELOAD_ABIERTA(state, value) {
            state.isRealodAbierta = value
        },
        SET_IS_RELOAD_EN_RUTA(state, value) {
            state.isRealodEnRuta = value
        },
        SET_IS_RELOAD_CERRADA(state, value) {
            state.isRealoadCerrada = value
        },
        SET_IS_RELOAD_CLIENTE_PASA(state, value) {
            state.isRealodClientePasa = value
        },
    },
    actions: {
        UPDATE_IS_RELOAD_ABIERTA({commit, state}, payload){
            commit('SET_IS_RELOAD_ABIERTA', payload)
        },
        UPDATE_IS_RELOAD_EN_RUTA({commit, state}, payload){
            commit('SET_IS_RELOAD_EN_RUTA', payload)
        },
        UPDATE_IS_RELOAD_CERRADA({commit, state}, payload){
            commit('SET_IS_RELOAD_CERRADA', payload)
        },
        UPDATE_IS_RELOAD_CLIENTE_PASA({commit, state}, payload){
            commit('SET_IS_RELOAD_CLIENTE_PASA', payload)
        }
    },
}